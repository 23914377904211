<template>
  <div class="catalog-item">
    <div class="item-catalog">
      <div class="item-left">
        <i class="item-icon el-icon-arrow-down"></i>
        <div v-if="isEdit">
          <el-input
            ref="catalogName"
            v-model="editName"
            placeholder="请输入目录名称"
            class="catalog-name"
          ></el-input>
          <el-button
            class="ml-16"
            type="text"
            :loading="submitting"
            icon="el-icon-check"
            @click="handleSave"
            >确定</el-button
          >
          <el-button
            type="text"
            :loading="submitting"
            icon="el-icon-close"
            @click="handleCancel"
            >取消</el-button
          >
        </div>
        <span v-else class="item-title">{{ itemData.Name }}</span>
      </div>
      <div class="item-right" v-show="finishedEditing">
        <el-button type="text" icon="el-icon-edit-outline" @click="handleEdit"
          >修改</el-button
        >
        <el-button type="text" icon="el-icon-delete" @click="handleDelete"
          >删除</el-button
        >
      </div>
    </div>
    <div class="item-course">
      <div class="material-list">
        <material-item
          v-for="(item, i) in listMaterial"
          :item-data="item"
          :key="i"
          @deleteMaterial="getMaterialList"
        ></material-item>
      </div>
      <div class="flex">
      <upload-material
        v-show="finishedEditing"
        @input="uploadedResourse"
      ></upload-material>
      <span class="remark-text">暂时只支持Pdf文件及Mp4格式的视频</span>
      </div>
    </div>
  </div>
</template>

<script>
import { createEntity, updateEntity, deleteEntity } from "@/api/coursecatalog";
import {
  createMaterial,
  getMaterialByCatalog,
  updateMaterial,
  deleteMaterial
} from "@/api/coursematerial";
import { mapState, mapMutations } from "vuex";
import UploadMaterial from "@/components/base/UploadChunk";
import MaterialItem from "./MaterialItem";
export default {
  components: { UploadMaterial, MaterialItem },
  props: {
    itemData: {
      type: Object,
      default() {
        return {
          Id: "",
          Name: "",
          Sort: 1,
          Series: "",
          isEdit: false
        };
      }
    }
  },
  data() {
    return {
      isEdit: false,
      submitting: false,
      editName: "",
      listMaterial: []
    };
  },
  computed: {
    ...mapState("course", ["finishedEditing"])
  },
  created() {
    this.getMaterialList();
  },
  mounted() {
    if (this.itemData.isEdit) {
      this.isEdit = true;
      this.$nextTick(() => {
        this.$refs.catalogName.focus();
      });
    }
  },
  methods: {
    ...mapMutations("course", ["Set_FinishedEditing"]),
    handleSave() {
      if (this.editName == "") {
        this.$message.error("名称不能为空！");
        return false;
      } else {
        this.itemData.Name = this.editName;
        if (this.itemData.Id) {
          updateEntity(this.itemData).then(res => {
            if (res.IsSuccess) {
              this.isEdit = false;
              this.$message.success("目录名更新成功！");
              this.Set_FinishedEditing(true);
            }
          });
        } else {
          createEntity(this.itemData).then(res => {
            if (res.IsSuccess) {
              this.isEdit = false;
              this.itemData.Id = res.Result;
              this.$message.success("目录添加成功！");
              this.Set_FinishedEditing(true);
            }
          });
        }
      }
    },
    handleDelete() {
      this.$confirm(
        "确定要删除所选目录及该目录下所有内容吗?删除后将无法恢复！",
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          deleteEntity(this.itemData.Id).then(res => {
            if (res.IsSuccess) {
              this.$message.success("删除成功！");
              this.$emit("handleDeleteCatalog", this.itemData.Id);
            } else {
              this.$message({
                type: "error",
                message: "删除失败!"
              });
            }
          });
        })
        .catch(err => {
          console.error(err);
        });
    },
    handleEdit() {
      this.isEdit = true;
      this.editName = this.itemData.Name;
      this.Set_FinishedEditing(false);
      this.$nextTick(() => {
        this.$refs.catalogName.focus();
      });
    },
    handleCancel() {
      this.editName = "";
      this.Set_FinishedEditing(true);
      this.isEdit = false;
      if (this.itemData.Id == "") {
        this.$emit("handleDeleteCatalog", this.itemData.Id);
      }
    },
    getMaterialList() {
      getMaterialByCatalog(this.itemData.Id).then(res => {
        this.listMaterial = res.Result;
        console.log("listMaterial", this.listMaterial);
      });
    },
    uploadedResourse(resourse) {
      console.log("resourse", resourse);
      const file = resourse;
      let from = {
        CatalogId: this.itemData.Id,
        SeriesId: this.itemData.SeriesId,
        FileId: file.Id,
        FileUrl: file.Path,
        IsRemote: false,
        Sort:
          this.listMaterial.lenght > 0
            ? this.listMaterial[this.listMaterial.lenght - 1].Sort
            : 1,
        Name: file.Name.substring(0, file.Name.lastIndexOf(".")),
        Type: file.Extension,
        TotalLength:file.VideoDuration,
        Status: 0
      };
      createMaterial(from).then(res => {
        if (res.IsSuccess) {
          this.$message.success("添加成功！");
          this.getMaterialList();
        } else {
          this.$message.error("上传失败请重试");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.catalog-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.item-catalog {
  width: 100%;
  height: 56px;
  background: #f6f6f6;
  border: 1px solid #e9e9e9;
  border-bottom: none;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  .catalog-name {
    width: 400px;
  }
  .item-icon {
    font-weight: 600;
    font-size: 18px;
    margin-right: 16px;
  }
  .item-title {
    font-size: 18px;
    font-weight: 600px;
  }
  .item-left {
    display: flex;
    align-items: center;
  }
  .item-right {
    display: none;
    align-items: center;
  }
  &:hover {
    .item-right {
      display: flex;
    }
  }
}
.item-course {
  display: flex;
  border: 1px solid #e9e9e9;
  border-bottom: none;
  display: flex;
  box-sizing: border-box;
  padding: 16px 48px;
  min-height: 56px;
  flex-direction: column;
  width: 100%;
}
.material-list ::v-deep{
  display: flex;
  width: 100%;
  flex-direction: column;
   .material_file_item {
    &:nth-of-type(2n + 1) {
      background: #f8f8f8;
    }
  }
}
.remark-text{
    font-size: 12px;
    color: #999999;
    margin-left: 16px;
    padding-top: 12px;
}
</style>
