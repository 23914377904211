<template>
  <div class="step-item">
    <div :class="`${active ? 'item-active' : ''} item-left`">
      <span class="left-text">{{ index }}</span>
    </div>
    <div :class="`${active ? 'item-active' : ''} item-right`">
      <span class="right-text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: String,
      default: "1",
    },
    text: {
      type: String,
      default: "基本信息",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.step-item {
  display: flex;
  align-items: center;
  margin-left: -32px;
}
.item-left {
  width: 60px;
  height: 60px;
  background: #eaecf3;
  border: 2px solid #ffffff;
  border-radius: 32px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-right {
  width: 293px;
  height: 48px;
  background: #eaecf3;
  position: relative;
  margin-left: -32px;
  z-index: 1;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.item-active {
  background-color: #3D5AFE !important;
  .left-text {
    color: #ffffff !important;
  }
  .right-text {
    color: #ffffff !important;
  }
}
.left-text {
  font-size: 24px;
  color: #999999;
}
.right-text {
  margin-left: 48px;
  font-size: 18px;
  color: #999999;
}
</style>