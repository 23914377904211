<template>
  <div class="material_file_item">
    <div class="material_file_item_content">
      <img width="21" height="26" :src="itemData.Type | fileTypeFilter" />
      <span class="material_file_title ellipsis">{{ itemData.Name }}</span>
    </div>
    <div class="material_file_item_buttons">
      <el-button
        type="text"
        icon="el-icon-delete"
        size="small"
        @click="handleDelete"
        >删除</el-button
      >
    </div>
    <!-- <span v-down="itemData" class="el-button el-button--text ml-20">
      <i class="el-icon-download" />下载
    </span> -->
  </div>
</template>

<script>
import Vue from "vue";
import { deleteMaterial } from "@/api/coursematerial";
export default {
  name: "MaterialItem",
  props: {
    itemData: {
      tepe: Object,
      default: () => {}
    }
  },
  filters: {
    fileTypeFilter(type) {
      let path = require("@/assets/images/filetype/pdf.png");
      switch (type) {
        case "docx":
        case "doc":
          path = require("@/assets/images/filetype/word.png");
          break;
        case "ppt":
        case "pptx":
          path = require("@/assets/images/filetype/ppt.png");
          break;
        case "png":
        case "jpg":
          path = require("@/assets/images/filetype/picture.png");
          break;
        case "xlsx":
        case "xls":
          path = require("@/assets/images/filetype/excel.png");
          break;
        case "mp4":
        case "avi":
          path = require("@/assets/images/filetype/video.png");
          break;
        default:
          break;
      }
      return path;
    }
  },
  mounted() {
    Vue.directive("down", {
      inserted: (el, binding) => {
        el.style.cssText = "cursor: pointer;color:write;";
        el.addEventListener("click", () => {
          
          console.log(binding.value);
          const link = document.createElement("a");
          const url = binding.value.FileUrlFullPath;
          const name = binding.value.Name;
          // 这里是将url转成blob地址，
          fetch(url)
            .then(res => res.blob())
            .then(blob => {
              // 将链接地址字符内容转变成blob地址
              link.href = URL.createObjectURL(blob);
              console.log(link.href);
              link.download = name;
              document.body.appendChild(link);
              link.click();
            });
        });
      }
    });
  },
  methods: {
    handleDelete() {
      
      this.$confirm("确定要删除该资源吗?删除后将无法恢复！", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteMaterial(this.itemData.Id).then(res => {
            if (res.IsSuccess) {
              this.$message.success("删除成功！");
              this.$emit("deleteMaterial", this.itemData.Id);
            } else {
              this.$message({
                type: "error",
                message: "删除失败!"
              });
            }
          });
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.material_file_item {
  display: flex;
  justify-content: space-between;
  padding: 8px 18px;
  width: 100%;
  cursor: pointer;
  height: 32px;
  border-bottom: 1px solid #eee;
  &:hover {
    .material_file_item_buttons {
      display: flex;
    }
    .material_file_title {
      color: #598DF3 !important;
    }
  }
  .material_file_item_content {
    display: flex;
    align-items: center;

    .material_file_title {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }
  }
  .material_file_item_buttons {
    width: 12px;
    justify-content: flex-end;
    display: none;
    // color: #f56c6c;
  }
}
</style>
