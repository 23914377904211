<template>
  <div>
    <div class="course-wrap" v-loading="loading">
      <div v-if="listCatalog.length == 0" class="btn-wrap">
        <div class="btn-add-wrap" @click="addCatalog">
          <i class="btn-icon el-icon-folder-add"></i>
          <span class="btn-text">新增目录</span>
        </div>
      </div>
      <template v-else>
        <!-- <div class="c-top">
          <span class="c-title">课程目录</span>
          <el-button v-if="finishedEditing" type="text" icon="el-icon-folder-add" @click="addCatalog">新增目录</el-button>
        </div> -->
        <div class="c-content">
          <catalog-item
            v-for="(item, i) in listCatalog"
            :key="item.Id"
            :item-data="item"
            @handleDeleteCatalog="handleDeleteCatalog"
          ></catalog-item>
          <div class="c-content-btn">
            <el-button
              v-if="finishedEditing"
              type="text"
              icon="el-icon-folder-add"
              @click="addCatalog"
              >新增目录</el-button
            >
          </div>
        </div>
      </template>
    </div>
    <div class="form-footer">
      <el-button
        type="primary"
        icon="el-icon-back"
        :loading="isSaving"
        @click="lastStep"
        plain
        >上一步</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-right"
        :loading="isSaving"
        @click="netStep"
        >下一步</el-button
      >
    </div>
  </div>
</template>

<script>
import CatalogItem from "./CatalogItem";
import { createEntity, getList, deleteEntity } from "@/api/coursecatalog";
import { mapState, mapMutations } from "vuex";
export default {
  name: "CalalogList",
  components: { CatalogItem },
  data() {
    return {
      listCatalog: [],
      catalogForm: {
        Id: "",
        Name: "",
        Sort: 1,
        SeriesId: "",
        ParentId: null,
        isEdit: true
      },
      isEdit: false,
      isSaving: false,
      stepIndex: 1,
      seriesId: this.$route.query.id,
      loading: true
    };
  },
  computed: {
    ...mapState("course", ["finishedEditing"])
  },
  created() {
    if (this.$route.query.id) {
      getList(this.$route.query.id).then(res => {
        this.listCatalog = res.Result;
        this.loading = false;
      });
    }
  },
  methods: {
    ...mapMutations("course", ["Set_FinishedEditing"]),
    addCatalog() {
      let newCatalog = Object.assign({}, this.catalogForm);
      if (this.listCatalog.length == 0) {
        newCatalog.Sort = 1;
      } else {
        newCatalog.Sort =
          this.listCatalog[this.listCatalog.length - 1].Sort + 1;
      }
      newCatalog.Name = `目录${newCatalog.Sort}`;
      newCatalog.SeriesId = this.seriesId;
      console.log(11, newCatalog);
      this.listCatalog.push(newCatalog);
      this.Set_FinishedEditing(false);
      // createEntity(newCatalog).then((res) => {
      //   if (res.IsSuccess) {
      //     newCatalog.Id = res.Result;

      //   }
      // });
    },
    handleDeleteCatalog(id) {
      let index = this.listCatalog.findIndex(p => p.Id == id);
      this.listCatalog.splice(index, 1);
    },
    lastStep() {
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery.step = 1;
      this.$router.replace({
        query: newQuery
      });
    },
    netStep() {
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery.step = 3;
      this.$router.replace({
        query: newQuery
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.course-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  margin-bottom: 32px;
}
.btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.btn-add-wrap {
  display: flex;
  width: 808px;
  height: 56px;
  background: #f6f6f6;
  border: 1px solid #e9e9e9;
  align-items: center;
  justify-content: center;
  // flex-direction: column;
  cursor: pointer;
  // transition: all 0.5s;
  border-radius: 2px;
  .btn-icon {
    color: #598DF3;
    font-size: 16px;
  }
  .btn-text {
    color: #598DF3;
    margin-left: 8px;
    font-size: 16px;
  }
  &:hover {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  }
}
.btn-add-wrap + .btn-add-wrap {
  margin-left: 16px;
}
.c-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  box-sizing: border-box;
  .c-title {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 600;
  }
}
.c-content {
  display: flex;
  flex-direction: column;
  width: 808px;
}
.c-content-btn {
  width: 100%;
  height: 56px;
  background: #f6f6f6;
  border: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 0 16px;
}
.form-footer {
  padding-top: 8px;
  margin-bottom: 20px;
}
</style>
