<template>
  <div class="content-body">
    <div class="form-body">
      <div class="form-content">
        <div class="step-list">
          <step-bar index="1" :active="stepIndex == '1'" text="基本信息" />
          <step-bar index="2" :active="stepIndex == '2'" text="资源管理" />
          <step-bar index="3" :active="stepIndex == '3'" text="完成" />
        </div>
        <series-form class="mt-16" v-if="stepIndex == '1'" :id="id"></series-form>
        <catalog-list :id="id" v-if="stepIndex == '2'"></catalog-list>
        <finish-edit :id="id" v-if="stepIndex == '3'"></finish-edit>
      </div>
    </div>
  </div>
</template>
<script>
import { createEntity, updateEntity, getInfo } from "@/api/courseseries";
import contentTools from "@/mixins/content-tools";
import { getEnterpriseAndStaffByUserId } from "@/api/enterprise/enterpriseStaff";
import StepBar from "@/components/base/StepBar";
import SeriesForm from "./SeriesForm";
import FinishEdit from "./FinishEdit";
import CatalogList from "./CatalogList";
export default {
  components: { StepBar, SeriesForm, FinishEdit, CatalogList },
  mixins: [contentTools],
  props: [],
  data() {
    return {
      id: "",
      formData: {
        Headline: "",
        Content: "",
        Summary: ""
      },
      submitting: false,
      rules: {
        Headline: [{ required: true, message: "请输入标题", trigger: "blur" }],
        Content: [{ required: true, message: "请输入标题", trigger: "blur" }]
      },
      enterPriseId: "",
      loaded: false,
      stepIndex: 1
    };
  },
  computed: {},
  watch: {},
  created() {
    this.id = this.$route.query.id || "";
    this.stepIndex = this.$route.query.step || 1;
    // this.initialize();
  },
  mounted() { },
  methods: {
    async initialize() {

      // var resEnterprise = await getEnterpriseAndStaffByUserId(
      //   this.$store.getters.userId
      // );
      // if (resEnterprise.IsSuccess && resEnterprise.Result.Enterprise) {
      //   this.enterPriseId = resEnterprise.Result.Enterprise.Id;
      // } else {
      //   this.$router.push("/");
      // }
      try {
        this.loaded = false;
        // 查看和编辑时需要获取详情
        if (this.id) {
          const res = await getInfo(this.id);
          if (res.IsSuccess) {
            this.formData = res.Result;
          }
        } else {
          this.formData.ReleaseTime = this.getNowDate();

          console.log("formData:", this.formData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded = true;
      }

    },
    submitForm() {
      console.log("postData:", this.formData);
      this.$refs.mainFrom.validate(async valid => {
        if (valid) {
          this.isUploading = true;
          let isSuccess = false;
          let message = "服务器错误！";
          this.formData.EnterpriseId = this.enterPriseId;
          var postData = Object.assign({}, this.formData);
          this.submitting = true;
          try {
            if (this.id) {
              const res = await updateEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            } else {
              const res = await createEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            }
            if (isSuccess) {
              this.$message.success(message || "保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isUploading = false;
            this.submitting = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误"
          });
          return;
        }
      });
    },
    updateForm(fieldName, value) {
      this.formData[fieldName] = value;
    },
    cancle() {
      if (!this.isView) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "温馨提示"
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.$router.go(-1);
      }
    }
  },
  watch: {
    $route(val) {
      console.log("newQuery", val);
      this.id = val.query.id;
      this.stepIndex = val.query.step;
    }
  }
};
</script>
<style lang="scss" scoped>
.form-content {
  display: flex;
  flex-direction: column;
}
.step-list {
  display: flex;
  margin-left: 32px;
  justify-content: center;
  padding: 16px;
}
</style>
