import request from "@/utils/request";
const controller = "/CourseMaterial";

export function getPageList(query) {
  return request({
    url: `${controller}/GetPageList`,
    method: "get",
    params: query
  });
}

export function getAllList() {
  return request({
    url: `${controller}/GetAllList`,
    method: "get"
  });
}
export function getInfo(id) {
  return request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: {
      id
    }
  });
}
export function createMaterial(data) {
  return request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
}

export function updateMaterial(data) {
  return request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
}

export function deleteMaterial(id) {
  return request({
    url: `${controller}/Delete`,
    method: "post",
    params: {
      id
    }
  });
}
export function getMaterialByCatalog(catalogId) {
  return request({
    url: `${controller}/GetListByCatalog`,
    method: "get",
    params: {
      catalogId
    }
  });
}
