<template>
  <div class="form-wrap">
    <div class="form-content">
      <el-image
        style="width: 245px; height: 200px"
        :src="require('@/assets/images/complete.png')"
      >
      </el-image>
      <div>您已完成课程编辑，是否现在上架课程？</div>
    </div>
    <div class="form-footer">
      <el-button
        type="primary"
        icon="el-icon-finished"
        :loading="isSaving"
        @click="submitForm"
        >现在上架</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-back"
        :loading="isSaving"
        @click="lastStep"
        plain
        >继续编辑</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-right"
        :loading="isSaving"
        @click="handleFinish"
        plain
        >以后再说</el-button
      >
    </div>
  </div>
</template>

<script>
// import RichText from "@/components/base/RichText";
// import { mapState, mapGetters, mapActions } from "vuex";
import { setStauts } from "@/api/courseseries";

export default {
  name: "Detail",
  components: {
    // RichText
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isShow: false,
      formData: {},
      config: {
        fieldsConfig: [],
        buttons: {
          onSubmitText: "提交",
          onResetText: "重置"
        }
      },
      isEdit: false,
      isSaving: false,
      stepIndex: 1,
      coverImageField: {},
      contentField: {},
      typeField: {},
      hostsInfo: "",
      isLoaded: false
    };
  },
  computed: {},
  mounted() {
    this.initialize();
  },
  created() {},
  methods: {
    initialize() {},
    async submitForm() {
      try {
        const response = await setStauts(this.id, 2);
        var isSuccess = response.IsSuccess;
        var msg = response.Message;
        this.isSaving = true;
        if (isSuccess) {
          this.$message({
            title: "成功",
            message: "上架成功",
            type: "success",
            duration: 2000
          });
          this.handleFinish();
        } else {
          this.$message({
            title: "上架失败",
            message: msg,
            type: "error",
            duration: 2000
          });
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        this.isSaving = false;
      }
    },
    lastStep() {
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery.step = 2;
      this.$router.replace({
        query: newQuery
      });
    },
    handleFinish() {
      if (this.$route.path.indexOf("enterprise") > -1) {
        this.$router.push("/enterprise/course");
      } else if (this.$route.path.indexOf("school") > -1) {
        this.$router.push("/school/course");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.form-wrap {
  display: flex;
  flex-direction: column;
  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin: 40px;
    div {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 33px;
    }
  }
}
</style>
