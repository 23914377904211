<template>
  <el-form :model="formData" ref="postForm" label-width="100px"  v-loading="!isLoaded" label-position="right" :rules="rules">
    <el-form-item label="课程名称" prop="Name">
      <el-input v-model="formData.Name" class="limit-input" placeholder="请输入，最长30个字" :maxlength="30" :show-word-limit="true" />
    </el-form-item>
    <el-form-item label="封面图片" prop="CoverImage">
      <picture-selector v-model="formData.CoverImage" name="CoverImage" ref="CoverImage" remarks="527*296" @input="updateForm">
      </picture-selector>
    </el-form-item>
    <el-form-item label="课程分类" prop="ClassificationId">
      <el-cascader v-model="formData.ClassificationId" :options="classificationTree" :props="classificationProps" :style="{ width: '100%' }"
        placeholder="请选择课程分类" @change="categoryChange" clearable></el-cascader>
    </el-form-item>
    <!-- <el-form-item label="售卖方式" class="background" prop="IsCharge">
      <el-form-item label="" label-width="20px" prop="IsCharge">
        <el-radio-group v-model="formData.IsCharge" >
          <el-radio :label="false">免费</el-radio>
          <el-radio :label="true" disabled>收费</el-radio>
        </el-radio-group>
        <span class="remark-text">试运行期暂时只支持发布免费资源</span>
      </el-form-item>
      <el-row v-if="formData.IsCharge">
        <el-col :span="7">
          <el-form-item label="课程价格" prop="Price">
            <el-input v-model="formData.Price" placeholder="请输入价格" type="number" />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <span class="ml-8">元</span>
        </el-col>
      </el-row>
    </el-form-item> -->
    <el-row>
      <el-col :span="24">
        <el-form-item label="课程简介" prop="Introduction">
          <rich-text v-if="isLoaded" v-model="formData.Introduction" name="Introduction" @input="updateForm"></rich-text>
        </el-form-item>
      </el-col>
    </el-row>

    <div class="form-footer">
      <el-button type="primary" icon="el-icon-right" :loading="isSaving" @click="submitForm">下一步</el-button>
    </div>
  </el-form>
</template>

<script>
import PictureSelector from "@/components/base/PictureSelector";
import RichText from "@/components/base/RichText";
import {
  createEntity,
  updateEntity,
  getInfo,
  getCourseClassification
} from "@/api/courseseries";
import { StaffTypeEnum } from "@/utils/constant";
// import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Detail",
  components: {
    PictureSelector,
    RichText
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    var checkAmount = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("收费金额必须为正数"));
      } else {
        callback();
      }
    };
    return {
      isShow: false,
      formData: {
        Name: "",
        CoverImage: "",
        IsCharge: false,
        ClassificationId: "",
        Price: 0,
        Enrollments: 0,
        OwnerType: 1,
        OwnerId: "",
        Introduction:''
      },
      config: {
        fieldsConfig: [],
        buttons: {
          onSubmitText: "提交",
          onResetText: "重置"
        }
      },
      isEdit: false,
      isSaving: false,
      stepIndex: 1,
      rules: {
        Name: [{ required: true, message: "请输入培训名称", trigger: "blur" }],
        CoverImage: [
          { required: true, message: "请上传封面", trigger: "blur" }
        ],
        IsCharge: [
          { required: true, message: "请选择收费模式", trigger: "blur" }
        ],
        Price: [
          { validator: checkAmount, trigger: "blur" },
          { required: true, message: "请输入培训结束时间", trigger: "blur" }
        ],
        StartTime: [
          { required: true, message: "请输入培训开始时间", trigger: "blur" }
        ],
        EndTime: [
          { required: true, message: "请输入培训结束时间", trigger: "blur" }
        ],
        ClassificationId: [
          { required: true, message: "请选择分类", trigger: "change" }
        ]
      },
      coverImageField: {},
      contentField: {},
      typeField: {},
      hostsInfo: "",
      isLoaded: false,
      classificationTree: [],
      classificationProps: {
        multiple: false,
        label: "Name",
        value: "Id",
        children: "Children"
      }
    };
  },
  computed: {},
  mounted() {
    if (
      this.$store.getters.employee &&
      this.$store.getters.employee.length > 0
    ) {
      //企业
      if (this.$route.path.indexOf("enterprise") > -1) {
        var unit = this.$store.getters.employee.find(
          p => p.StaffType == StaffTypeEnum.Enterprise
        );
        if (unit) {
          this.formData.OwnerType = StaffTypeEnum.Enterprise;
          this.formData.OwnerId = unit.Employer.Id;
        }
        console.log(111222, this.formData);
      } else if (this.$route.path.indexOf("school") > -1) {
        var unit = this.$store.getters.employee.find(
          p => p.StaffType == StaffTypeEnum.School
        );
        if (unit) {
          this.formData.OwnerType = StaffTypeEnum.School;
          this.formData.OwnerId = unit.Employer.Id;
        }
        console.log(111222, this.formData);
      }
    } else {
      this.$message("您没有权限进行此操作！");
      this.$router.push("/");
    }
    this.initialize();
  },
  created() { },
  methods: {
    initialize() {
      getCourseClassification().then(res => {
        this.classificationTree = res.Result;
        console.log("classificationTree", this.classificationTree);
      });
      if (this.id) {
        this.isEdit = true;
        getInfo(this.id).then(res => {
          this.formData = res.Result;
          this.isLoaded = true;
        });
      } else {
        this.isLoaded = true;
      }
    },
    updateForm(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.formData[fieldName] = value;
      // console.log(this.formData)
    },
    async submitForm() {
      console.log(this.formData);
      this.loading = true;
      var isSuccess = false;
      var msg = "服务器错误！";
      var postData = Object.assign({}, this.formData);
      console.log(postData);

      // this.$refs.mainForm.$refs.form.validate(async valid => {
      this.$refs.postForm.validate(async valid => {
        try {
          if (valid) {
            this.isSaving = true;
            var id = this.id;

            if (this.isEdit) {
              const response = await updateEntity(postData);
              isSuccess = response.IsSuccess;
              msg = response.Message;
            } else {
              const response = await createEntity(postData);
              isSuccess = response.IsSuccess;
              msg = response.Message;
              id = response.Result;
              console.log(response);
            }
            this.loading = false;
            if (isSuccess) {
              this.$message({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
              let newQuery = JSON.parse(JSON.stringify(this.$route.query));
              newQuery.id = id;
              newQuery.step = 2;
              this.$router.replace({
                query: newQuery
              });
            } else {
              this.$message({
                title: "保存失败",
                message: msg,
                type: "error",
                duration: 2000
              });
            }
          } else {
            this.$message({
              title: "保存失败",
              message: "表单填写有误",
              type: "warning",
              duration: 2000
            });
            return false;
          }
        } finally {
          this.isSaving = false;
        }
      });
    },
    changeRouter() {
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery.step = 2;
      this.$router.replace({
        query: newQuery
      });
    },
    categoryChange(e) {
      this.formData.ClassificationId = e.length > 0 ? e[e.length - 1] : "";
    }
  }
};
</script>
<style lang="scss" scoped>
.background ::v-deep {
  .el-form-item__content {
    background: #f4f4f4;
    &:nth-child(2) {
      padding-bottom: 5px;
    }
  }
}
.form-footer {
  margin: 18px 0 20px 0;
}
.remark-text {
  margin-left: 32px;
  font-size: 14px;
  color: #999999;
}
</style>
